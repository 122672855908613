<template>
  <section class="services">
    <b-card tag="article" class="card--services card--no-border">
      <template>
        <!--v-slot:header -->
        <article>
          <b-row>
            <b-col :md="isHome ? 12 : 6">
              <router-link :to="ServiceDetails">
                <section class="img-wrap ratio-75">
                  <div class="img-content">
                    <b-img fluid :src="service.image ? service.image.path : ''"></b-img>
                  </div>
                </section>
              </router-link>
            </b-col>
            <b-col :md="isHome ? 12 : 6">
              <h4 class="card-title text-center mt-2">{{ service.title }}</h4>

              <read-more
                :link="ServiceDetails"
                class="card-text read-more"
                :more-str="$t('label.more')"
                :text="service.description ? service.description : ''"
                :max-chars="60"
              ></read-more>

              <section class="card--text-wrapper">
                <div class="card--action text-center">
                  <Button
                    class="button--ask p-0 mt-3"
                    @click="toggleOverlay"
                    variant="link"
                    :text="$t('label.ask_for_service')"
                  ></Button>
                </div>
              </section>
            </b-col>
          </b-row>
        </article>
      </template>
    </b-card>
  </section>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    service: {
      type: Object,
      default: () => {
        return {
          slug: null,
          id: null,
          title: "",
          description: "",
          image: null
        };
      }
    },
    isHome: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ServiceDetails() {
      return `/services/details/${this.service.id}`;
    }
  },
  methods: {
    toggleOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "service",
        isOpen: true
      });
    }
  }
};
</script>
<style  scoped>
.card-title{
  color: #060e49;
}
</style>
